import React, { Component } from "react";
import { Form, Button, Container, Card, Row, Col } from "react-bootstrap";

export default class App extends Component {
  state = {
    count: 0,
  };

  handleIncrement = () => {
    console.log("Increment Clicked");
    this.setState({
      count: this.state.count + 1,
    });
  };

  handleDecrement = () => {
    console.log("Decrement Clicked");
    this.setState({
      count: this.state.count - 1,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control type="email" placeholder="Enter Email" />
              <Form.Text className="text-muted">Cmon, pls</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Check me out" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Container>
      </React.Fragment>
    );
  }
}
